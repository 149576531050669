<template>
  <div>
    <b-container fluid class="content">
      <b-row class="mt-4">
        <b-col>
          <b-card no-body>
            <b-card-body>
              <b-form>
                <!-- row 1 -->
                <b-row>
                  <b-col>
                    <base-input :label="$t('Customer')">
                      <select class="form-control" v-model="cur_customer">
                        <option></option>
                        <option
                          v-for="(customer, idx) in customer_list"
                          :key="idx"
                          :value="customer.IDn"
                        >
                          {{ customer.CompanyName }}
                        </option>
                      </select>
                    </base-input>
                  </b-col>
                  <b-col>
                    <base-input :label="$t('Date Type')">
                      <select class="form-control" v-model="date_type">
                        <option
                          v-for="(type, idx) in date_type_list"
                          :key="idx"
                          :value="type.IDn"
                        >
                          {{ type.Name }}
                        </option>
                      </select>
                    </base-input>
                  </b-col>
                  <b-col>
                    <base-input :label="$t('From Date')">
                      <flat-pickr
                        :config="pickr_config"
                        class="form-control"
                        v-model='from_date'
                      >
                      </flat-pickr>
                    </base-input>
                  </b-col>
                  <b-col>
                    <base-input :label="$t('To Date')">
                      <flat-pickr
                        :config="pickr_config"
                        class="form-control"
                        v-model='to_date'
                      >
                      </flat-pickr>
                    </base-input>
                  </b-col>
                </b-row>
                <!-- row 2 -->
                <b-row>
                  <b-col>
                    <base-input :label="$t('Store')">
                      <select class="form-control" v-model="cur_store_id">
                        <option></option>
                        <option
                          v-for="(loc, idx) in storelist"
                          :key="idx"
                          :value="loc.LocationID"
                        >
                          {{ loc.LocationName }}
                        </option>
                      </select>
                    </base-input>
                  </b-col>
                  <b-col>
                    <base-input :label="$t('BySales')">
                      <select class="form-control" v-model="cur_sales_idn">
                        <option value=""></option>
                        <option
                          v-for="sales in sales_list"
                          :key="sales.IDn"
                          :value="sales.IDn"
                        >
                          {{ sales.UserName }}
                        </option>
                      </select>
                    </base-input>
                  </b-col>
                  <b-col>
                    <base-input :label="$t('Min Balance')">
                      <input type="text" class="form-control" v-model="cur_min_balance">
                    </base-input>
                  </b-col>
                  <b-col>
                    <base-input :label="$t('Max Balance')">
                      <input type="text" class="form-control" v-model="cur_max_balance">
                    </base-input>
                  </b-col>
                </b-row>
                <!-- row 3 -->
                <b-row class="justify-content-lg-center">
                  <b-col class="text-center" lg="2">
                    <b-button variant="primary" @click="form_search">
                      {{ $t("Search") }}
                    </b-button>
                  </b-col>
                </b-row>
              </b-form>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-container>

    <b-container fluid>
      <b-card>
        <b-card-body :style="{overflowX: 'auto' }">
          <div>
            <div v-if="list_data && list_data.length > 0">
              <!-- single -->
              <div v-if="saved_customer" style="width: 1000px;">
                <div>
                  <b-row>
                    <b-col class="text-center" lg="2">
                      <b-button variant="primary" @click="export_pdf">
                        {{ $t("Export PDF") }}
                      </b-button>
                      <!-- <a v-bind:href="export_url" class="btn btn-primary" target="_blank">{{ $t("Export PDF") }}</a> -->
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col><div>&nbsp;</div></b-col>
                  </b-row>
                  <!-- title -->
                  <b-row>
                    <b-col>
                      <b-row>
                        <b-col><div style="font-weight: 600; font-size: 30px;">{{ other_data.store.LocationName }}</div></b-col>
                      </b-row>
                      <b-row>
                        <b-col>{{ other_data.store.LocationAddress1 }}</b-col>
                      </b-row>
                      <b-row v-if="other_data.store.LocationAddress2">
                        <b-col>{{ other_data.store.LocationAddress2 }}</b-col>
                      </b-row>
                      <b-row>
                        <b-col>{{ other_data.store.LocationCity }}, {{ other_data.store.LocationProvince }}, {{ other_data.store.LocactionZIP }} {{ other_data.store.LocationCountry }}</b-col>
                      </b-row>
                      <b-row>
                        <b-col>{{ other_data.store.LocationTel }}</b-col>
                      </b-row>
                    </b-col>
                    <b-col>
                      <b-row>
                        <b-col cols="3"></b-col>
                        <b-col><div style="font-weight: 800; font-size: 36px;">STATEMENT</div></b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="3"></b-col>
                        <b-col><div style="font-weight: bold;">Statement Date</div></b-col>
                        <b-col>{{ saved_datestr }}</b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="3"></b-col>
                        <b-col><div style="font-weight: bold;">From</div></b-col>
                        <b-col>{{ saved_from_date }}</b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="3"></b-col>
                        <b-col><div style="font-weight: bold;">to</div></b-col>
                        <b-col>{{ saved_to_date }}</b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col><div>&nbsp;</div></b-col>
                  </b-row>
                  <!-- bill to, ship to -->
                  <b-row>
                    <b-col>
                      <b-row>
                        <b-col>
                          <b-row>
                            <b-col cols="3"><div style="font-weight: bold;">Bill To:</div></b-col>
                            <b-col cols="9">{{ saved_customer_object.CompanyName }}</b-col>
                          </b-row>
                          <b-row>
                            <b-col cols="3"></b-col>
                            <b-col cols="9">{{ other_data.bill_addr.Street1 }}</b-col>
                          </b-row>
                          <b-row v-if="other_data.bill_addr.Street2">
                            <b-col cols="3"></b-col>
                            <b-col cols="9">{{ other_data.bill_addr.Street2 }}</b-col>
                          </b-row>
                          <b-row>
                            <b-col cols="3"></b-col>
                            <b-col cols="9">{{ other_data.bill_addr.AddressCity }}, {{ other_data.bill_addr.AddressProvince }}, {{ other_data.bill_addr.ZIP }} {{ other_data.bill_addr.AddressCountry }}</b-col>
                          </b-row>
                          <b-row>
                            <b-col cols="3"></b-col>
                            <b-col cols="9">{{ saved_customer_object.TEL }}</b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col>
                      <b-row>
                        <b-col>
                          <b-row>
                            <b-col cols="3"><div style="font-weight: bold;">Ship To:</div></b-col>
                            <b-col cols="9">{{ saved_customer_object.CompanyName }}</b-col>
                          </b-row>
                          <b-row>
                            <b-col cols="3"></b-col>
                            <b-col cols="9">{{ other_data.ship_addr.Street1 }}</b-col>
                          </b-row>
                          <b-row v-if="other_data.ship_addr.Street2">
                            <b-col cols="3"></b-col>
                            <b-col cols="9">{{ other_data.ship_addr.Street2 }}</b-col>
                          </b-row>
                          <b-row>
                            <b-col cols="3"></b-col>
                            <b-col cols="9">{{ other_data.ship_addr.AddressCity }}, {{ other_data.ship_addr.AddressProvince }}, {{ other_data.ship_addr.ZIP }} {{ other_data.ship_addr.AddressCountry }}</b-col>
                          </b-row>
                          <b-row>
                            <b-col cols="3"></b-col>
                            <b-col cols="9">{{ saved_customer_object.TEL }}</b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </div>
                <div v-bind:style="{ 'margin-top': '5px' }">
                  <!-- head -->
                  <b-row v-bind:style="{ backgroundColor: 'lightGrey' }">
                    <b-col><div v-bind:style="headerStyleObject">Invoice Date</div></b-col>
                    <b-col><div v-bind:style="headerStyleObject">Invoice #</div></b-col>
                    <b-col><div v-bind:style="headerStyleObject">Customer PO #</div></b-col>
                    <b-col><div v-bind:style="headerStyleObject">Total</div></b-col>
                    <b-col><div v-bind:style="headerStyleObject">Payment</div></b-col>
                    <b-col><div v-bind:style="headerStyleObject">Balance</div></b-col>
                  </b-row>
                  <!-- body -->
                  <b-row :key='"so_" + key1' v-for='(so, key1) in list_data'>
                    <b-col style="border-bottom: 1px solid black;">
                      <b-row>
                        <b-col><div v-bind:style="generalStyleObject">{{ so.SOEndDate ? (so.SOEndDate.length > 10 ? so.SOEndDate.substr(0, 10) : so.SOEndDate) : "" }}</div></b-col>
                        <b-col><div v-bind:style="generalStyleObject">{{ so.InvoiceNo }}</div></b-col>
                        <b-col><div v-bind:style="generalStyleObject">{{ so.CustomerPONumber }}</div></b-col>
                        <b-col><div v-bind:style="generalStyleObject">{{ "$ " + parseFloat(so.TotalAmount).toFixed(2) }}</div></b-col>
                        <b-col><div v-bind:style="generalStyleObject">{{ "$ " + parseFloat(so.PaidAmount).toFixed(2) }}</div></b-col>
                        <b-col><div v-bind:style="generalStyleObject">{{ "$ " + parseFloat(so.Balance).toFixed(2) }}</div></b-col>
                      </b-row>
                      <b-row :key='"payment_" + key2' v-for='(payment, key2) in so.payment_list'>
                        <b-col><div v-bind:style="generalStyleObject">{{ key2 == 0 ? 'Payment:' : '' }}</div></b-col>
                        <b-col><div v-bind:style="generalStyleObjectLeft">{{ (payment.PaymentDateTime.length > 10 ? payment.PaymentDateTime.substr(0, 10) : payment.PaymentDateTime)
                                                                        + ' / $' + parseFloat(payment.PaymentAmount).toFixed(2)
                                                                        + ' / ' + payment.PaymentMethodID
                                                                        + ' / ' + payment.CreateBy }}</div></b-col>
                      </b-row>
                      <b-row :key='"credit_" + key3' v-for='(credit, key3) in so.credit_list'>
                        <b-col><div v-bind:style="generalStyleObject">{{ key3 == 0 ? 'Credit:' : '' }}</div></b-col>
                        <b-col><div v-bind:style="generalStyleObjectLeft">{{ (credit.CreateDateTime.length > 10 ? credit.CreateDateTime.substr(0, 10) : credit.CreateDateTime)
                                                                        + ' / $' + parseFloat(credit.PaymentAmount).toFixed(2)
                                                                        + ' / ' + credit.Remarks }}</div></b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </div>
                <div>
                  <b-row v-bind:style="{ backgroundColor: 'lightGrey' }">
                    <b-col cols="10"><div v-bind:style="headerStyleObject">Balance Due:</div></b-col>
                    <b-col cols="2"><div v-bind:style="headerStyleObject">${{ parseFloat(other_data.total_balance).toFixed(2) }}</div></b-col>
                  </b-row>
                  <b-row>
                    <b-col><div>&nbsp;</div></b-col>
                  </b-row>
                  <b-row>
                    <b-col style="border: 1px solid black;">
                      <b-row>
                        <b-col style="text-align: center; border-bottom: 1px solid black;border-right: 1px solid black;"><div>1-30 Days</div></b-col>
                        <b-col style="text-align: center; border-bottom: 1px solid black;border-right: 1px solid black;"><div>31-60 Days</div></b-col>
                        <b-col style="text-align: center; border-bottom: 1px solid black;border-right: 1px solid black;"><div>61-90 Days</div></b-col>
                        <b-col style="text-align: center; border-bottom: 1px solid black;border-right: 1px solid black;"><div>Over 90 Days</div></b-col>
                        <b-col style="text-align: center; border-bottom: 1px solid black;"><div>Amount Due</div></b-col>
                      </b-row>
                      <b-row>
                        <b-col style="text-align: center; border-right: 1px solid black;"><div>${{ parseFloat(other_data.balance_classes.class_1).toFixed(2) }}</div></b-col>
                        <b-col style="text-align: center; border-right: 1px solid black;"><div>${{ parseFloat(other_data.balance_classes.class_2).toFixed(2) }}</div></b-col>
                        <b-col style="text-align: center; border-right: 1px solid black;"><div>${{ parseFloat(other_data.balance_classes.class_3).toFixed(2) }}</div></b-col>
                        <b-col style="text-align: center; border-right: 1px solid black;"><div>${{ parseFloat(other_data.balance_classes.class_4).toFixed(2) }}</div></b-col>
                        <b-col style="text-align: center;"><div>${{ parseFloat(other_data.total_balance).toFixed(2) }}</div></b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                </div>
              </div>
              <!-- multi -->
              <div v-else>
                <!-- Export row-->
                <b-row>
                  <b-col class="text-center" style="margin-bottom: 10px" lg="3">
                    <xlsx-workbook @change="change_for_report" @created="created_for_report">
                      <xlsx-sheet
                        :collection="sheet.data"
                        v-for="sheet in export_sheets_for_report"
                        :key="sheet.name"
                        :sheet-name="sheet.name"
                      />
                      <xlsx-download :filename="export_filename" :options="export_options" disable-wrapper-click>
                        <template #default="{ download }">
                          <b-button variant="primary" @click="export_for_edit(download)">
                            {{ $t("Export Excel") }}
                          </b-button>
                        </template>
                      </xlsx-download>
                    </xlsx-workbook>
                  </b-col>
                  <!-- <b-col class="text-center" lg="2">
                    <a v-bind:href="export_url" class="btn btn-primary" target="_blank">{{ $t("Export PDF") }}</a>
                  </b-col> -->
                </b-row>
                <!-- Title row -->
                <b-row style="margin-bottom: 10px">
                  <b-col><div v-bind:style="titleStyleObject">{{ $t("Sales Statement") }}</div></b-col>
                  <b-col><div v-bind:style="titleStyleObject2"> - {{ $t("By Customer") }}</div></b-col>
                  <b-col></b-col>
                  <b-col></b-col>
                  <b-col></b-col>
                </b-row>
                <!-- Search conditions row --> 
                <b-row>
                  <b-col cols="12">
                    <div class="float-right">
                      {{ $t('Time') + ': ' }} &emsp; <span style="font-weight: bold;">{{ saved_from_date }}</span>&emsp;
                      {{ " " + $t('To') + " "}} &emsp; <span style="font-weight: bold;">{{ saved_to_date }}</span>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col cols="2">
                    {{ $t('Customer') + ': ' }} &emsp; <span style="font-weight: bold;">{{ $t("ALL") }}</span>
                  </b-col>
                  <b-col cols="3">
                    {{ $t('Date Type') + ': ' }} &emsp; <span style="font-weight: bold;">{{ saved_datetype_name }}</span> 
                  </b-col>
                  <b-col cols="3">
                    {{ $t('Store') + ': ' }} &emsp; <span style="font-weight: bold;">{{ saved_store_name }}</span>
                  </b-col>
                  <b-col cols="2">
                    {{ $t('Sales') + ': ' }} &emsp; <span style="font-weight: bold;">{{ saved_sales_name }}</span>
                  </b-col>
                  <b-col cols="1">
                    {{ $t('Min') + ': ' }} &emsp; <span style="font-weight: bold;">{{ saved_min_balance }}</span>
                  </b-col>
                  <b-col cols="1">
                    {{ $t('Max') + ': ' }} &emsp; <span style="font-weight: bold;">{{ saved_max_balance }}</span>
                  </b-col>
                </b-row>
                <!-- Table header row --> 
                <b-row v-bind:style="{ backgroundColor: 'lightGrey' }">
                  <b-row class="col-3 ">
                    <b-col cols="6">
                      <div style="text-align: left; fontWeight: bold; fontSize: 17px;">{{ $t('Invoice Date') }}</div>
                    </b-col>
                    <b-col cols="6">
                      <div style="text-align: center; fontWeight: bold; fontSize: 17px;">{{ $t('Invoice') + "#" }}</div>
                    </b-col>
                  </b-row>
                  <b-col cols="1">
                    <div style="text-align: right ; fontWeight: bold; fontSize: 17px;">{{ $t('Total') }}</div>
                  </b-col>
                  <b-row class="col-5">
                    <b-col cols="3">
                      <div style="text-align: right; fontWeight: bold; fontSize: 17px;">{{ "1-30 " + $t('Days') }}</div>
                    </b-col>
                    <b-col cols="3">
                      <div style="text-align: right; fontWeight: bold; fontSize: 17px;">{{ "31-60 " + $t('Days') }}</div>
                    </b-col>
                    <b-col cols="3">
                      <div style="text-align: right; fontWeight: bold; fontSize: 17px;">{{ "61-90 " + $t('Days') }}</div>
                    </b-col>
                    <b-col cols="3">
                      <div style="text-align: right; fontWeight: bold; fontSize: 17px;">{{ $t('Over') + " 90 " + $t('Days') }}</div>
                    </b-col>
                  </b-row>
                  <b-col cols="1">
                    <div style="text-align: right; fontWeight: bold; fontSize: 17px;">{{ $t('Payment') }}</div>
                  </b-col>
                  <b-col cols="1">
                    <div style="text-align: right; fontWeight: bold; fontSize: 17px;">{{ $t('Balance') }}</div>
                  </b-col>
                  <b-col cols="1">
                    <div style="text-align: right; fontWeight: bold; fontSize: 17px;">{{ 'By ' + $t('Sales') }}</div>
                  </b-col>
                </b-row>
                <!-- Table content --> 
                  <b-row v-bind:key="customer_key" v-for="(customer, customer_key) in list_data_multi" v-bind:style="{ marginTop: '5px' }">
                    <b-col  class="col-12">
                      <div v-bind:style="titleStyleObject">
                        {{ $t('Customer') + ': ' + customer['CustomerName'] }}
                      </div>
                    </b-col>
                    <b-col  class="col-12">
                      <b-row v-bind:key="list_key" v-for="(list, list_key) in customer['data']">
                        <b-col  class="col-12">
                          <b-row v-bind:style="{ marginTop: '5px' }"  v-if="customer['CustomerName']">
                            <b-row class="col-3">
                              <b-col cols="6">
                                <div style="text-align: left; fontSize: 17px;">{{ list['SOEndDate'].substr(0,10) }}</div>
                              </b-col>
                              <b-col cols="6">
                                <div style="text-align: center; fontSize: 17px;">{{ list['InvoiceNo'] }}</div>
                              </b-col>
                            </b-row>
                            <b-col cols="1">
                              <div style="text-align: right; fontSize: 17px;">{{ "$ " + parseFloat(list['TotalAmount']).toFixed(2) }}</div>
                            </b-col>
                            <b-row class="col-5">
                              <b-col cols="3">
                                <div style="text-align: right; fontSize: 17px;">{{ "$ " + list['class_1'] }}</div>
                              </b-col>
                              <b-col cols="3">
                                <div style="text-align: right; fontSize: 17px;">{{ "$ " + list['class_2'] }}</div>
                              </b-col>
                              <b-col cols="3">
                                <div style="text-align: right; fontSize: 17px;">{{ "$ " + list['class_3'] }}</div>
                              </b-col>
                              <b-col cols="3">
                                <div style="text-align: right; fontSize: 17px;">{{ "$ " + list['class_4'] }}</div>
                              </b-col>
                            </b-row>
                            <b-col cols="1">
                              <div style="text-align: right; fontSize: 17px;">{{ "$ " + parseFloat(list['PaidAmount']).toFixed(2) }}</div>
                            </b-col>
                            <b-col cols="1">
                              <div style="text-align: right; fontSize: 17px;">{{ "$ " + parseFloat(list['Balance']).toFixed(2) }}</div>
                            </b-col>
                            <b-col cols="1">
                              <div style="text-align: right; fontSize: 17px;">{{ list['OrderByName'] }}</div>
                            </b-col>
                          </b-row>
                          <b-row :key='"payment_" + key2' v-for='(payment, key2) in list.payment_list'>
                            <b-col><div v-bind:style="generalStyleObject">{{ key2 == 0 ? 'Payment:' : '' }}</div></b-col>
                            <b-col><div v-bind:style="generalStyleObjectLeft">{{ (payment.PaymentDateTime.length > 10 ? payment.PaymentDateTime.substr(0, 10) : payment.PaymentDateTime)
                                                                            + ' / $' + parseFloat(payment.PaymentAmount).toFixed(2)
                                                                            + ' / ' + payment.PaymentMethodID
                                                                            + ' / ' + payment.CreateBy }}</div></b-col>
                          </b-row>
                          <b-row :key='"credit_" + key3' v-for='(credit, key3) in list.credit_list'>
                            <b-col><div v-bind:style="generalStyleObject">{{ key3 == 0 ? 'Credit:' : '' }}</div></b-col>
                            <b-col><div v-bind:style="generalStyleObjectLeft">{{ (credit.CreateDateTime.length > 10 ? credit.CreateDateTime.substr(0, 10) : credit.CreateDateTime)
                                                                            + ' / $' + parseFloat(credit.PaymentAmount).toFixed(2)
                                                                            + ' / ' + credit.Remarks }}</div></b-col>
                          </b-row>
                        </b-col>
                      </b-row>
                    </b-col>
                    <b-col class="col-12">
                      <hr style="margin: 0px">
                      <b-row v-bind:style="{ marginTop: '5px' }"  v-if="customer['CustomerName']">
                        <b-row class="col-3">
                          <b-col cols="6">
                            <div style="text-align: left; fontSize: 17px;">{{ " " }}</div>
                          </b-col>
                          <b-col cols="6">
                            <div style="text-align: right; fontSize: 17px; fontWeight: bold;">{{ $t("Total") }}</div>
                          </b-col>
                        </b-row>
                        <b-col cols="1">
                          <div style="text-align: right; fontSize: 17px; fontWeight: bold;">{{ "$ " + parseFloat(customer['CustomerTotal']).toFixed(2) }}</div>
                        </b-col>
                        <b-row class="col-5">
                          <b-col cols="3">
                            <div style="text-align: right; fontSize: 17px; fontWeight: bold;">{{ "$ " + parseFloat(customer['CustomerClass1']).toFixed(2) }}</div>
                          </b-col>
                          <b-col cols="3">
                            <div style="text-align: right; fontSize: 17px; fontWeight: bold;">{{ "$ " + parseFloat(customer['CustomerClass2']).toFixed(2) }}</div>
                          </b-col>
                          <b-col cols="3">
                            <div style="text-align: right; fontSize: 17px; fontWeight: bold;">{{ "$ " + parseFloat(customer['CustomerClass3']).toFixed(2) }}</div>
                          </b-col>
                          <b-col cols="3">
                            <div style="text-align: right; fontSize: 17px; fontWeight: bold;">{{ "$ " + parseFloat(customer['CustomerClass4']).toFixed(2) }}</div>
                          </b-col>
                        </b-row>
                        <b-col cols="1">
                          <div style="text-align: right; fontSize: 17px; fontWeight: bold;">{{ "$ " + parseFloat(customer['CustomerPayment']).toFixed(2) }}</div>
                        </b-col>
                        <b-col cols="1">
                          <div style="text-align: right; fontSize: 17px; fontWeight: bold;">{{ "$ " + parseFloat(customer['CustomerBalance']).toFixed(2) }}</div>
                        </b-col>
                        <b-col cols="1">
                          <div style="text-align: right; fontSize: 17px;">{{ " " }}</div>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
              </div>
            </div>
            <div v-else>
              {{ $t('No Records') }}
            </div>
          </div>
        </b-card-body>
      </b-card>
    </b-container>
    <vue-element-loading
      :active="showloading"
      spinner="bar-fade-scale"
      color="#FF6700"
      duration="2.0"
      background-color="rgba(255, 255, 255, .5)"
      size="100"
    />
  </div>
</template>
<script>
import { Table, TableColumn, Button, Select, Option } from "element-ui";
import flatPicker from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { getStore, setStore } from "../../util/helper";
import { XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx";
// import SearchAutocomplete from "../Components/SearchAutocomplete";
import VueElementLoading from "vue-element-loading";

const axios = require("axios");

export default {
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Select.name]: Select,
    [Option.name]: Option,
    [flatPicker.name]: flatPicker,
    VueElementLoading,
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
    // SearchAutocomplete,
  },
  data() {
    return {
      pickr_config: {
        dateFormat: "Y-m-d",
      },
      titleStyleObject: {
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '26px',
      },
      titleStyleObject2: {
        textAlign: 'left',
        'padding-top': '5px',
        fontWeight: 'bold',
        fontSize: '20px',
      },
      titleStyleObject3: {
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '18px',
      },
      titleStyleObject4: {
        textAlign: 'left',
        fontSize: '18px',
      },
      headerStyleObject: {
        textAlign: 'right',
        fontWeight: 'bold',
        fontSize: '18px',
      },
      generalStyleObject: {
        textAlign: 'right',
        fontSize: '16px',
      },
      generalStyleObjectLeft: {
        textAlign: 'left',
        fontSize: '16px',
      },
      headerStyleObjectLong: {
        textAlign: 'right',
        fontWeight: 'bold',
        fontSize: '18px',
        width: '150px'
      },
      generalStyleObjectLong: {
        textAlign: 'right',
        fontSize: '16px',
        width: '150px'
      },
      headerStyleObjectLong2: {
        textAlign: 'right',
        fontWeight: 'bold',
        fontSize: '18px',
        width: '200px'
      },
      generalStyleObjectLong2: {
        textAlign: 'right',
        fontSize: '16px',
        width: '200px'
      },
      level1StyleObject: {
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '16px',
      },
      level2StyleObject: {
        'padding-left': '20px',
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '16px',
      },
      level3StyleObject: {
        'padding-left': '40px',
        textAlign: 'left',
        fontWeight: 'bold',
        fontSize: '16px',
      },
      leafStyleObject: {
        textAlign: 'right',
        fontSize: '16px',
      },

      token: "",
      user_id: 0,
      export_sheets_for_report: [{ name: "Statement", data: [[1,2,3,4],[5,6,7,8]] }],
      export_filename: '',
      export_options: {Sheets: {"so report": {'!cols': [{wch:20}, {wch:30}, {wch:40}, {wch:30}, {wch:10}, {wch:10}]}}},   // not work
      export_url: '',
      showloading: false,

      list_data: null,
      list_data_multi: null,
      other_data: null,
      report_width: null,
      title_width: 1000,
      table_width: null,

      categorylist: [],
      storelist: null,
      customer_list: [],
      sales_list: [],
      date_type_list: [{IDn: 1, Name: 'Order Date'}, 
                       {IDn: 2, Name: 'Invoice Date'}],

      // search conditions
      date_type: 2,
      from_date: null,
      to_date: null,
      cur_min_balance: null,
      cur_max_balance: null,
      cur_store_id: null,
      cur_customer: null,
      cur_sales_idn: null,

      saved_store_name: '',
      saved_customer_name: '',
      saved_customer_object: null,
      saved_sales_name: '',
      saved_max_balance: '',
      saved_min_balance: '',
      saved_datetype_name: '',
      saved_date_type: '',
      saved_from_date: '',
      saved_to_date: '',
      saved_datestr: '',
      saved_customer: '',
      saved_store_id: '',
      saved_sales_idn: '',
    };
  },
  watch: {},
  computed: {
    apiBaseUrl: function () {
      return axios.defaults.baseURL + "/Api";
    },
  },
  methods: {
    async get_list_data() {
      this.showloading = true;
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      bodyFormData.append("date_type", this.date_type);
      
      var url = "";
      if (axios.defaults.baseURL != undefined) {
        url = axios.defaults.baseURL;
      }
      url += "/Api/Web/Statement_by_customer/pdf?";
      url += "token=" + this.token;
      url += "&user_id=" + this.user_id;
      url += "&date_type=" + this.date_type;

      if (this.from_date) {
        bodyFormData.append("from_date", this.from_date);
        url += "&from_date=" + this.from_date;
      }
      if (this.to_date) {
        bodyFormData.append("to_date", this.to_date);
        url += "&to_date=" + this.to_date;
      }
      if (this.cur_min_balance && this.cur_min_balance.trim()) {
        bodyFormData.append("cur_min_balance", this.cur_min_balance);
        url += "&cur_min_balance=" + this.cur_min_balance;
      }
      if (this.cur_max_balance && this.cur_max_balance.trim()) {
        bodyFormData.append("cur_max_balance", this.cur_max_balance);
        url += "&cur_max_balance=" + this.cur_max_balance;
      }
      if (this.cur_store_id) {
        bodyFormData.append("cur_store_id", this.cur_store_id);
        url += "&cur_store_id=" + this.cur_store_id;
      }
      if (this.cur_customer) {
        bodyFormData.append("cur_customer", this.cur_customer);
        url += "&cur_customer=" + this.cur_customer;
      }
      if (this.cur_sales_idn) {
        bodyFormData.append("cur_sales_idn", this.cur_sales_idn);
        url += "&cur_sales_idn=" + this.cur_sales_idn;
      }

      this.save_conditions();
      url += "&saved_store_name=" + this.saved_store_name;
      url += "&saved_customer_name=" + this.saved_customer_name;
      url += "&saved_sales_name=" + this.saved_sales_name;
      url += "&saved_datetype_name=" + this.saved_datetype_name;
      url += "&saved_max_balance=" + this.saved_max_balance;
      url += "&saved_min_balance=" + this.saved_min_balance;
      this.export_url = url;
      
      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Statement_by_customer/search",
        data: bodyFormData,
      })
        .then(function (response) {
          that.showloading = false;
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.list_data = rt.data.list_data;
              that.other_data = rt.data.other_data;
              that.format_list_data_multi();
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
          else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          that.showloading = false;
          // console.log(error);
          that.$router.push("/login");
        });
    },
    async export_pdf() {
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);
      if(this.saved_store_id) bodyFormData.append("cur_store_id", this.saved_store_id);
      if(this.saved_customer) bodyFormData.append("cur_customer", this.saved_customer);
      if(this.saved_sales_idn) bodyFormData.append("cur_sales_idn", this.saved_sales_idn);
      if(this.saved_date_type) bodyFormData.append("date_type", this.saved_date_type);
      if(this.saved_max_balance) bodyFormData.append("max_balance", this.saved_max_balance);
      if(this.saved_min_balance) bodyFormData.append("min_balance", this.saved_min_balance);
      if(this.saved_to_date) bodyFormData.append("to_date", this.saved_to_date);
      if(this.saved_from_date) bodyFormData.append("from_date", this.saved_from_date);
      let filename = 'Statement for ' + this.saved_customer_name + '_' + this.yyyymmdd() + '.pdf';
      
      axios({
        method: "post",
        url: "/Api/Web/Statement_by_customer/pdf_single_customer",
        data: bodyFormData,
        responseType: 'blob',
      })
        .then(response => {
          // Create a download link for the received PDF file
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', filename);
          document.body.appendChild(link);
          link.click();
        })
        .catch(error => {
          console.error('Error exporting PDF:', error);
        });
    },
    yyyymmdd() {
      var now = new Date();
      var y = now.getFullYear();
      var m = now.getMonth() + 1;
      var d = now.getDate();
      var mm = m < 10 ? '0' + m : m;
      var dd = d < 10 ? '0' + d : d;
      return '' + y + mm + dd;
    },
    format_list_data_multi(){
      if(!this.list_data) return;
      const source = JSON.parse(JSON.stringify(this.list_data));
      
      let after = {};
      for(var i=0; i<source.length; i++){
        var obj = source[i];
        if(!after.hasOwnProperty(obj.SoldToCustomerIDn)){
          after[obj.SoldToCustomerIDn] = {
            data: [],
            CustomerIDn: obj.SoldToCustomerIDn,
            CustomerName: this.getNameByID(obj.SoldToCustomerIDn),
            CustomerTotal: 0,
            CustomerClass1: 0,
            CustomerClass2: 0,
            CustomerClass3: 0,
            CustomerClass4: 0,
            CustomerPayment: 0,
            CustomerBalance: 0,
          };
        }
        after[obj.SoldToCustomerIDn].data.push(obj);
        after[obj.SoldToCustomerIDn].CustomerTotal += Number(obj.TotalAmount);
        after[obj.SoldToCustomerIDn].CustomerClass1 += Number(obj.class_1);
        after[obj.SoldToCustomerIDn].CustomerClass2 += Number(obj.class_2);
        after[obj.SoldToCustomerIDn].CustomerClass3 += Number(obj.class_3);
        after[obj.SoldToCustomerIDn].CustomerClass4 += Number(obj.class_4);
        after[obj.SoldToCustomerIDn].CustomerPayment += Number(obj.PaidAmount);
        after[obj.SoldToCustomerIDn].CustomerBalance += Number(obj.Balance);
      }
      this.list_data_multi = after;
    },
    getNameByID(id){
      for (var key in this.customer_list) {
        // skip loop if the property is from prototype
        if (!this.customer_list.hasOwnProperty(key)) continue;

        var obj = this.customer_list[key];
        for (var prop in obj) {
          // skip loop if the property is from prototype
          if (!obj.hasOwnProperty(prop)) continue;

          if(obj.IDn == id){
            return obj.CompanyName;
          }
        }
      }
    },
    async form_search() {
      this.list_data_multi = [];
      this.get_list_data();
    },
    get_today() {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();

      return yyyy + '-' + mm + '-' + dd;
    },
    async init_data() {
      this.from_date = this.get_today();
      this.to_date = this.get_today();
      this.token = getStore("token");
      this.user_id = getStore("user_id");
      this.get_init_data();
    },
    async get_init_data() {
      this.showloading = true;
      var bodyFormData = new FormData();
      bodyFormData.append("token", this.token);
      bodyFormData.append("user_id", this.user_id);

      var that = this;
      axios({
        method: "post",
        url: "/Api/Web/Statement_by_customer",
        data: bodyFormData,
      })
        .then(function (response) {
          that.showloading = false;
          if (response.status == "200") {
            var rt = response.data;
            if (rt.status == 0) {
              that.storelist = rt.data.storelist;
              that.sales_list = rt.data.sales_list;
              that.customer_list = rt.data.customer_list;
            } else if (rt.status == 4) {
              alert(that.$t("No Permission"));
              that.$router.go(-1);
            } else if (rt.message != undefined) {
              alert(rt.message);
            }
          }
          else {
            that.$router.push("/login");
          }
        })
        .catch(function (error) {
          that.showloading = false;
          // console.log(error);
          that.$router.push("/login");
        });
    },
    change_for_report(sheet) {
      // change event will emit twice when sheets is set, xlsx sheets is set to empty at first event, then xlsx sheets is set to the new sheets.
      if (this.callback_for_report && sheet.SheetNames && sheet.SheetNames.length > 0) {
        this.callback_for_report();
      }
    },
    created_for_report() {
      this.callback_for_report = '';
    },
    remove_dash(myString) {
      return myString.replace(/-/g, "");
    },
    export_for_edit(callback) {
      let datestr = new Date().toLocaleDateString();
      this.export_filename = 'Statement_' + this.remove_dash(this.saved_from_date) + '_' + this.remove_dash(this.saved_to_date) + '.xlsx';
      this.export_sheets_for_report[0].data = this.generate_excel_data();
      this.callback_for_report = callback;
    },
    save_conditions() {
      this.saved_customer = this.cur_customer;
      this.saved_store_id = this.cur_store_id;
      this.saved_store_name = 'All Store';
      if (this.cur_store_id) this.saved_store_name = this.storelist.hasOwnProperty(this.cur_store_id) ? this.storelist[this.cur_store_id].LocationName : 'Error Store';
      this.saved_customer_name = 'All Customer';
      if (this.cur_customer) {
        this.saved_customer_object = this.customer_list.hasOwnProperty(this.cur_customer) ? this.customer_list[this.cur_customer] : null;
        this.saved_customer_name = this.customer_list.hasOwnProperty(this.cur_customer) ? this.customer_list[this.cur_customer].CompanyName : 'Error Customer';
      }
      this.saved_sales_idn = this.cur_sales_idn;
      this.saved_sales_name = 'All Sales';
      if (this.cur_sales_idn) this.saved_sales_name = this.sales_list.hasOwnProperty(this.cur_sales_idn) ? this.sales_list[this.cur_sales_idn].UserName : 'Error Sales';
      this.saved_date_type = this.date_type;
      this.saved_datetype_name = '';
      switch (this.date_type) {
        case 1:
          this.saved_datetype_name = 'Order Date';
          break;
        case 2:
          this.saved_datetype_name = 'Invoice Date';
          break;
        default:
          break;
      }
      this.saved_datestr = this.get_today();
      this.saved_from_date = this.from_date;
      this.saved_to_date = this.to_date;
      this.saved_max_balance = this.cur_max_balance;
      this.saved_min_balance = this.cur_min_balance;
    },
    generate_conditions() {
      // conditions
      let excel_data = [];
      excel_data.push(['Date Type:','From:','To:','Customer:','Store:','Sales:','Min Balance:','Max Balance:']);
      excel_data.push([this.saved_datetype_name, this.saved_from_date, this.saved_to_date, this.saved_customer,
                       this.saved_store_name, this.saved_sales_name, this.saved_min_balance, this.saved_max_balance]);
      return excel_data;
    },
    generate_excel_data() {
      // conditions
      let excel_data = this.generate_conditions();
      excel_data.push([]);
      
      // table header
      excel_data.push(['Invoice Date:','Invoice#:','Total:','1-30 Days:','31-60 Days:','61-90 Days:','90 Days +:', 'Payment:', 'Balance:', 'By Sales:']);

      // data
      const customers = this.list_data_multi;
      for (var key in customers) {
        excel_data.push(['Customer: ' + customers[key].CustomerName]);
        for(var i=0; i<customers[key].data.length; i++){
          let cur_so = customers[key].data[i];
          excel_data.push([(cur_so.SOEndDate).substr(0, 10), cur_so.InvoiceNo, parseFloat(cur_so.TotalAmount).toFixed(2), parseFloat(cur_so.class_1).toFixed(2), parseFloat(cur_so.class_2).toFixed(2), 
                           parseFloat(cur_so.class_3).toFixed(2), parseFloat(cur_so.class_4).toFixed(2), parseFloat(cur_so.PaidAmount).toFixed(2), parseFloat(cur_so.Balance).toFixed(2), cur_so.OrderByName]);
          for(var j=0; j<cur_so.payment_list.length; j++){
            let payment = cur_so.payment_list[j];
            if(j == 0){
              excel_data.push(['', '', '', 'Payment:', payment.PaymentDateTime.substr(0, 10), '$ ' + parseFloat(payment.PaymentAmount).toFixed(2), payment.PaymentMethodID, payment.CreateBy]);
            } else {
              excel_data.push(['', '', '', '', payment.PaymentDateTime.substr(0, 10), '$ ' + parseFloat(payment.PaymentAmount).toFixed(2), payment.PaymentMethodID, payment.CreateBy]);
            }
          }
          for(var j=0; j<cur_so.credit_list.length; j++){
            let credit = cur_so.credit_list[j];
            if(j == 0){
              excel_data.push(['', '', '', 'Credit:', credit.CreateDateTime.substr(0, 10), '$ ' + parseFloat(credit.PaymentAmount).toFixed(2), credit.Remarks]);
            } else {
              excel_data.push(['', '', '', '', credit.CreateDateTime.substr(0, 10), '$ ' + parseFloat(credit.PaymentAmount).toFixed(2), credit.Remarks]);
            }
          }
        }
        excel_data.push(['', 'Total:', customers[key].CustomerTotal, customers[key].CustomerClass1, customers[key].CustomerClass2, 
                        customers[key].CustomerClass3, customers[key].CustomerClass4, customers[key].CustomerPayment, customers[key].CustomerBalance]);
        excel_data.push([]);
      }

      return excel_data;
    },
  },
  mounted() {
    this.init_data();
    this.windowHeight = window.innerHeight;
  },
};
</script>
<style>
.content {
  padding-top: 80px;
}
</style>
